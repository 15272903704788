import React, { Component } from "react";
import { Col, Row, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import SmallButton from "../../components/SmallButton";
import Checkbox from "../../components/Checkbox";
import SegmentedControl from "../../components/SegmentedControl";
import InputBox from "../../components/InputBox";
import InputPointMap from "../Map/InputPointMap";
import * as screenViewActions from "../../actions/screenViews";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";
import styled from "styled-components";

class ScreenViewMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mapMode: "simple_select",
      aspectRatioString: "16:9",
      aspectRatio: 16/9,
      width: 0,
      height: 0,
      customBearing: 0,
      showCustomBearing: false,
      useInitialLocation: false,
      useYourLocation: false,
      hasLoadedState: false
    };

    this.containerRef = React.createRef(); // Creating a ref for the container

    this.onAspectRatioChange = this.onAspectRatioChange.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onInitialLocationBoundsChanged = this.onInitialLocationBoundsChanged.bind(this);
    this.onCopyInitialLocation = this.onCopyInitialLocation.bind(this);
    this.onYourLocationPointChanged = this.onYourLocationPointChanged.bind(this);
    this.onClearYourLocation = this.onClearYourLocation.bind(this);
    this.onMapModeChange = this.onMapModeChange.bind(this);

    this.onHideMapBackgroundChanged = this.onHideMapBackgroundChanged.bind(this);
    this.onBackgroundColorChanged = this.onBackgroundColorChanged.bind(this);
    this.onUseThreeDChanged = this.onUseThreeDChanged.bind(this);
    this.onUseCircleLabelsChanged = this.onUseCircleLabelsChanged.bind(this);
    this.onShowVacantChanged = this.onShowVacantChanged.bind(this);
    this.onShowOccupiedChanged = this.onShowOccupiedChanged.bind(this);
    this.onShowVacantBookedChanged = this.onShowVacantBookedChanged.bind(this);
    this.onInitialBearingChanged = this.onInitialBearingChanged.bind(this);
    this.onCustomBearingChanged = this.onCustomBearingChanged.bind(this);
    this.onInitialPitchChanged = this.onInitialPitchChanged.bind(this);
    this.onInitialLocationNELatitudeChanged = this.onInitialLocationNELatitudeChanged.bind(this);
    this.onInitialLocationNELongitudeChanged = this.onInitialLocationNELongitudeChanged.bind(this);
    this.onInitialLocationSWLatitudeChanged = this.onInitialLocationSWLatitudeChanged.bind(this);
    this.onInitialLocationSWLongitudeChanged = this.onInitialLocationSWLongitudeChanged.bind(this);
    this.onClearInitialLocation = this.onClearInitialLocation.bind(this);
    this.onYourLocationLatitudeChanged= this.onYourLocationLatitudeChanged.bind(this);
    this.onYourLocationLongitudeChanged = this.onYourLocationLongitudeChanged.bind(this);
    this.onYourLocationColorChanged = this.onYourLocationColorChanged.bind(this);
    this.onStatusFilterChanged = this.onStatusFilterChanged.bind(this);
    this.onLabelFilterChanged = this.onLabelFilterChanged.bind(this);
    this.onIconFilterChanged = this.onIconFilterChanged.bind(this);
    this.onViewLocationClick = this.onViewLocationClick.bind(this);
    this.onChangeLocationClick = this.onChangeLocationClick.bind(this);
    this.mapConfigurationElement = this.mapConfigurationElement.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("ScreenViewMap.getDerivedStateFromProps", nextProps, prevState);

    // Only copy props if they exist and if screen id is correct
    if (!isEmpty(nextProps.screenViewForm) && !isEmpty(nextProps.screenViewForm.id) && nextProps.screenViewId === nextProps.screenViewForm.id) {

      const formView = nextProps.screenViewForm;
      const mapComponent = get(formView.layout, nextProps.pathToMap);

      const floorId = get(mapComponent, "mapLocationId", null);
      if (floorId) {

        // Fetch location
        if (!get(nextProps.locations, floorId)) {
          nextProps.getScreenLocation(floorId);
        }

        // Fetch floor map
        if (!get(nextProps.maps, floorId)) {
          nextProps.getScreenFloorMap(floorId);
        }

        if (prevState.hasLoadedState) {
          return null;
        }

        const initialBearing = get(mapComponent, "initialBearing", 0);

        const newState = {
          showCustomBearing: ![0, 90, 180, 270].includes(initialBearing),
          customBearing: initialBearing,
          hasLoadedState: true
        };

        return newState;
      }
      else {
        const newState = {
          hasLoadedState: true
        };
        
        return newState;
      }
    }

    return null;
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    const container = this.containerRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const { aspectRatio } = this.state;
    let height = containerHeight;
    let width = containerHeight * aspectRatio;

    if (width > containerWidth) {
      width = containerWidth;
      height = width / aspectRatio;
    }

    this.setState({ width, height });
  }

  onAspectRatioChange(event) {

    const container = this.containerRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const newAspectRatio = event.target.value;

    let aspectRatio = newAspectRatio.replaceAll(":", "/").replaceAll(" ", "");
    const splitAspectRatio = aspectRatio.split("/");
    if (splitAspectRatio.length !== 2 || isNaN(splitAspectRatio[0]) || isNaN(splitAspectRatio[1])) {
      aspectRatio = 16/9;
    }
    else {
      aspectRatio = splitAspectRatio[0] / splitAspectRatio[1];
    }

    let height = containerHeight;
    let width = containerHeight * aspectRatio;

    if (width > containerWidth) {
      width = containerWidth;
      height = width / aspectRatio;
    }

    this.setState({ aspectRatio, aspectRatioString: newAspectRatio, width, height });
  }

  onInitialLocationBoundsChanged(ne, sw) {
    this.setState({ initialLocation: { ne, sw } });
  }

  onCopyInitialLocation() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.initialLocation = this.state.initialLocation;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onYourLocationPointChanged(point) {
    const lat = get(point, "geometry.coordinates[1]", 0);
    const lng = get(point, "geometry.coordinates[0]", 0);

    if (lat && lng) {
      const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    
      if (!mapComponent.yourLocation) {
        mapComponent.yourLocation = {};
      }

      mapComponent.yourLocation.lat = lat;
      mapComponent.yourLocation.lng = lng;
      this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
    }
  }

  onClearYourLocation() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.yourLocation = null;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
    this.onMapModeChange("simple_select");
  }

  onMapModeChange(mode) {
    this.setState({ mapMode: mode });
  }

  onViewLocationClick(floorId) {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${floorId}/locations`);
  }

  onChangeLocationClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/views/${this.props.screenViewId}/map/${this.props.match.params.mapIndex}/locations/root`);
  }

  onHideMapBackgroundChanged() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.hideMapBackground = !get(mapComponent, "hideMapBackground", false);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onBackgroundColorChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.backgroundColor = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onUseThreeDChanged() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.useThreeD = !get(mapComponent, "useThreeD", false);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onUseCircleLabelsChanged() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.useCircleLabels = !get(mapComponent, "useCircleLabels", false);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onShowVacantChanged() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.showVacant = !get(mapComponent, "showVacant", true);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onShowOccupiedChanged() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.showOccupied = !get(mapComponent, "showOccupied", true);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onShowVacantBookedChanged() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.showVacantBooked = !get(mapComponent, "showVacantBooked", false);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onInitialBearingChanged(value) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));

    if (value === "custom") {
      mapComponent.initialBearing = this.state.customBearing;
    }
    else {
      mapComponent.initialBearing = value;
    }

    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
    this.setState({ showCustomBearing: (value === "custom") });
  }

  onCustomBearingChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.initialBearing = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
    this.setState({ customBearing: event.target.value });
  }

  onInitialPitchChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.initialPitch = Number(event.target.value);
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onInitialLocationNELatitudeChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));

    if (!mapComponent.initialLocation) {
      mapComponent.initialLocation = { ne: {}, sw: {} };
    }

    mapComponent.initialLocation.ne.lat = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onInitialLocationNELongitudeChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));

    if (!mapComponent.initialLocation) {
      mapComponent.initialLocation = { ne: {}, sw: {} };
    }

    mapComponent.initialLocation.ne.lng = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onInitialLocationSWLatitudeChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));

    if (!mapComponent.initialLocation) {
      mapComponent.initialLocation = { ne: {}, sw: {} };
    }

    mapComponent.initialLocation.sw.lat = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onInitialLocationSWLongitudeChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));

    if (!mapComponent.initialLocation) {
      mapComponent.initialLocation = { ne: {}, sw: {} };
    }

    mapComponent.initialLocation.sw.lng = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }


  onClearInitialLocation() {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.initialLocation = null;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onYourLocationLatitudeChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    
    if (!mapComponent.yourLocation) {
      mapComponent.yourLocation = {};
    }
    
    mapComponent.yourLocation.lat = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onYourLocationLongitudeChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    
    if (!mapComponent.yourLocation) {
      mapComponent.yourLocation = {};
    }
    
    mapComponent.yourLocation.lng = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onYourLocationColorChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    
    if (!mapComponent.yourLocation) {
      mapComponent.yourLocation = {};
    }
    
    mapComponent.yourLocation.color = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onStatusFilterChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.statusFilter = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onLabelFilterChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.labelFilter = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onIconFilterChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.iconFilter = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onLocationIdsChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.locationIds = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  onSVGUrlChanged(event) {
    const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
    mapComponent.url = event.target.value;
    this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
  }

  mapConfigurationElement(mapComponent) {

    const canEdit = this.props.auth.hasAdminRole;

    if (!mapComponent) {
      return null;
    }

    // const policyElement = (
    //   <>
    //     <Freetext header="Data source" content="Define a filter that controls where the map get its data from and what that data includes." />
    //     <Row>
    //       <Col>
    //       </Col>
    //     </Row>
    //   </>
    // );

    const commonOptions = (
      <>
        <Freetext header="Room status in map" />
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show vacant status" isChecked={get(mapComponent, "showVacant", true)} onClick={this.onShowVacantChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show occupied status" isChecked={get(mapComponent, "showOccupied", true)} onClick={this.onShowOccupiedChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show no-show status" description="(activates 5 min after the meeting starts)" isChecked={get(mapComponent, "showVacantBooked", false)} onClick={this.onShowVacantBookedChanged} disabled={!canEdit} />
          </Col>
        </Row>

        <div style={{ marginTop: "40px" }} />
        <Freetext header="Filters" content='Enhance your map presentations by applying location filters in a flexible format. Utilize "and", "or", and "not" conditions to specify the types of locations you want to display. When you set these filters, the map will only show locations that match your specified criteria. For instance, using the filter "zone|room|!toilet" will result in the map displaying data for all zones and rooms, excluding toilets. This allows for a more tailored and precise presentation of location-based data on your map.' />
        <InputBox
          label="Status filter (which location type should show status)"
          placeholder="ex. meeting|quiet"
          value={get(mapComponent, "statusFilter", "")}
          onChange={this.onStatusFilterChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "400px" }}
        />
        <InputBox
          label="Label filter (which location type should show labels)"
          placeholder="ex. meeting|zone"
          value={get(mapComponent, "labelFilter", "")}
          onChange={this.onLabelFilterChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "400px" }}
        />
      </>
    );

    // MazeMap specific options
    if (mapComponent.type === "map") {
      
      // Validate hex color
      const yourLocationColorValid = /^(#[A-Fa-f0-9]{3,6})?$/i.test(get(mapComponent, "yourLocation.color", ""));

      const floorId = get(mapComponent, "mapLocationId", null);

      const floorSelectorElement = (
        <>
          <Freetext header="Floor map" content="A floor location is required to display a map." />
          <Row>
            <Col>
              { floorId && <SmallButton text="View floor" color="white" onClick={() => this.onViewLocationClick(floorId)} disabled={!canEdit} noLeftMargin /> }
              { floorId && <SmallButton text="Change floor" color="white" onClick={this.onChangeLocationClick} disabled={!canEdit} /> }
              { !floorId && <SmallButton text="Select floor" color="white" onClick={this.onChangeLocationClick} disabled={!canEdit} noLeftMargin /> }
            </Col>
          </Row>
        </>
      );

      const stylingElement = (
        <>
          <Freetext header="Map styling" />
          <div style={{ marginTop: "20px" }} />
          <Row>
            <Col>
              <Checkbox label="Show map in 3D" description="(requires MazeMap 3D maps)" isChecked={get(mapComponent, "useThreeD", false)} onClick={this.onUseThreeDChanged} disabled={!canEdit} />
            </Col>
          </Row>
          <div style={{ marginTop: "20px" }} />
          <Row>
            <Col>
              <Checkbox label="Hide map background" description="(remove parks, roads, buildings etc. from the surroundings)" isChecked={get(mapComponent, "hideMapBackground", false)} onClick={this.onHideMapBackgroundChanged} disabled={!canEdit} />
            </Col>
          </Row>
          <InputBox
            label="Background color in map (hex)"
            placeholder="ex. #f2f2f2"
            value={get(mapComponent, "backgroundColor", "")}
            onChange={this.onBackgroundColorChanged}
            disabled={!canEdit}
            style={{ width: "100%", maxWidth: "200px" }}
          />
        </>
      );

      const locationMarkerElement = (
        <>
          <Freetext header={`Location marker`} headerDescription="(optional)" content={`Choose a placement for a "you are here" marker in the map. Either by placing a marker in the map or enter coordinates manually.`} />
          <div style={{ width: "100%", maxWidth: "400px" }}>
            <InputBox 
              label="Latitude"
              placeholder="ex. 59.8993"
              value={get(mapComponent, "yourLocation.lat", "")} 
              valid={Number(get(mapComponent, "yourLocation.lat", 0))} 
              onChange={this.onYourLocationLatitudeChanged} 
              required 
              showValidIcon
              disabled={!canEdit} 
              style={{ paddingTop: "0px" }}
            />
            <InputBox
              label="Longitude"
              placeholder="ex. 10.6282"
              value={get(mapComponent, "yourLocation.lng", "")} 
              valid={Number(get(mapComponent, "yourLocation.lng", 0))} 
              onChange={this.onYourLocationLongitudeChanged} 
              required 
              showValidIcon
              disabled={!canEdit} 
            />
            <InputBox 
              label="Marker color (hex)" 
              placeholder="ex. #1C4D82"
              value={get(mapComponent, "yourLocation.color", "")} 
              valid={yourLocationColorValid} 
              onChange={this.onYourLocationColorChanged} 
              required 
              showValidIcon
              disabled={!canEdit} 
            />
            <SmallButton
              text={get(mapComponent, "yourLocation.lat", false) || get(mapComponent, "yourLocation.lng", false) ? "Clear marker" : (this.state.mapMode === "simple_select" ? "Place marker in map" : "Cancel placement") }
              color={this.state.mapMode === "simple_select" && !get(mapComponent, "yourLocation.lat", false) && !get(mapComponent, "yourLocation.lng", false) ? "blue" : "red"}
              onClick={() => {
                if (get(mapComponent, "yourLocation.lat", false) || get(mapComponent, "yourLocation.lng", false)) {
                  this.onClearYourLocation();
                }
                else {
                  this.onMapModeChange(this.state.mapMode === "simple_select" ? "draw_point" : "simple_select")
                }
              }}
              disabled={!canEdit}
              style={{ marginLeft: "0px", marginTop: "20px" }}
            />
          </div>
        </>
      );

      const mapBoundsElement = (
        <>
          {/* NB! The screen might have a different aspect ratio than what is shown here. */}
          <Freetext header={`Map rotation, pitch and bounds`} headerDescription="(optional)" content={`Choose the rotation and pitch of the map first, then the boundaries. Either pan/zoom in the map or enter coordinates. Both north-east and south-west is required to define a boundary. Default will zoom to show the whole floor map.`} />
          <div style={{ marginTop: "10px" }} />
          <SegmentedControl
            name="bearing"
            title="Initial map rotation"
            value={this.state.showCustomBearing ? "custom" : get(mapComponent, "initialBearing", 0)}
            onChange={this.onInitialBearingChanged} 
            options={[ 
              { label: "North", value: 0, default: true },
              { label: "East", value: 90 },
              { label: "South", value: 180 },
              { label: "West", value: 270 },
              { label: "Custom", value: "custom" }
            ]}
            disabled={!canEdit}
          />
          { this.state.showCustomBearing && 
            <InputBox
              label="Custom bearing (North=0, East=90, South=180, West=270)"
              value={this.state.customBearing}
              onChange={this.onCustomBearingChanged}
              valid={Number(this.state.customBearing)}
              showValidIcon
              required
              style={{ maxWidth: "400px" }}
              disabled={!canEdit}
            />
          }

          <InputBox
            label="Initial pitch (min=0, max=60)"
            value={get(mapComponent, "initialPitch", 0)}
            onChange={this.onInitialPitchChanged}
            valid={!isNaN(get(mapComponent, "initialPitch", 0)) && get(mapComponent, "initialPitch", 0) >= 0 && get(mapComponent, "initialPitch", 0) <= 60}
            showValidIcon
            required
            style={{ maxWidth: "400px" }}
            disabled={!canEdit}
          />
          
          <div style={{ width: "100%", maxWidth: "400px", paddingTop: "20px" }}>
            <InputBox 
              label="North-east latitude"
              placeholder="ex. 59.8991"
              value={get(mapComponent, "initialLocation.ne.lat", "")} 
              valid={Number(get(mapComponent, "initialLocation.ne.lat", 0))} 
              onChange={this.onInitialLocationNELatitudeChanged} 
              required
              showValidIcon
              disabled={!canEdit}
              // style={{ paddingTop: "0px" }}
              />
            <InputBox 
              label="North-east longitude"
              placeholder="ex. 10.6294"
              value={get(mapComponent, "initialLocation.ne.lng", "")} 
              valid={Number(get(mapComponent, "initialLocation.ne.lng", 0))} 
              onChange={this.onInitialLocationNELongitudeChanged} 
              required
              showValidIcon
              disabled={!canEdit}
              />
            <InputBox 
              label="South-west latitude"
              placeholder="ex. 59.8982"
              value={get(mapComponent, "initialLocation.sw.lat", "")} 
              valid={Number(get(mapComponent, "initialLocation.sw.lat", 0))} 
              onChange={this.onInitialLocationSWLatitudeChanged} 
              required
              showValidIcon
              disabled={!canEdit}
              />
            <InputBox 
              label="South-west longitude"
              placeholder="ex. 10.6276"
              value={get(mapComponent, "initialLocation.sw.lng", "")} 
              valid={Number(get(mapComponent, "initialLocation.sw.lng", 0))} 
              onChange={this.onInitialLocationSWLongitudeChanged} 
              required
              showValidIcon
              disabled={!canEdit}
              />
            <SmallButton
              text="Copy from map"
              onClick={this.onCopyInitialLocation}
              disabled={!canEdit}
              style={{ marginLeft: "0px", marginTop: "20px" }}
              />
            <SmallButton
              text="Clear"
              color="white"
              onClick={this.onClearInitialLocation}
              disabled={!canEdit}
              style={{ marginTop: "20px" }}
              />
          </div>
        </>
      );

      return (
        <>
          <div style={{ paddingTop: "25px" }} />
          { floorSelectorElement }
          <div style={{ marginTop: "40px" }} />
          { stylingElement }
          <div style={{ marginTop: "40px" }} />
          { commonOptions }
          <div style={{ marginTop: "40px" }} />
          { mapBoundsElement }
          <div style={{ marginTop: "40px" }} />
          { locationMarkerElement }
        </>
      );
    }
    
    const mapSourceElement = (
      <>
        <Freetext header="Map source" content="A publicly available map in SVG format." />
        <InputBox 
          label="Map source URL"
          placeholder="ex. https://example.com/floor.svg"
          value={get(mapComponent, "url", "")} 
          valid={get(mapComponent, "url", "")}
          onChange={this.onSVGUrlChanged} 
          required
          showValidIcon
        />
      </>
    )

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        { mapSourceElement }
        <div style={{ marginTop: "40px" }} />
        { commonOptions }
      </>
    );
  }

  render() {
    console.log("ScreenConfigDetails.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const view = this.props.screenViewForm;
    const layout = get(view, "layout", {});
    const mapComponent = get(layout, this.props.pathToMap);

    console.log("ScreenConfigDetails.mapComponent", mapComponent);
    
    if (!mapComponent) {
      return null;
    }

    let rightSide = null;
    if (mapComponent.type === "map") {

      // Get yourLocation to render in map
      let yourLocationFeature = null;
      const yourLocationLat = get(mapComponent, "yourLocation.lat", 0);
      const yourLocationLng = get(mapComponent, "yourLocation.lng", 0);
      if (yourLocationLat && yourLocationLng) {
        yourLocationFeature = { 
          type: "Feature",
          geometry: { 
            type: "Point", 
            coordinates: [yourLocationLng, yourLocationLat] 
          }
        };
      }

      // Add the map to the right side
      const location = get(this.props.locations, mapComponent.mapLocationId, null);
      const map = get(this.props.maps, mapComponent.mapLocationId, null);
      rightSide = (
        <>
          <MapContainerOptions>
            <InputTitle>Display aspect ratio</InputTitle>
            <InputBox value={this.state.aspectRatioString} placeholder="ex. 16:9" onChange={this.onAspectRatioChange} style={{ maxWidth: "120px" }} />
          </MapContainerOptions>
          <CenteredWrapper ref={this.containerRef}>
            <SizeWrapper width={this.state.width} height={this.state.height}>
              <MapContainer>
                { location && map && this.state.aspectRatio && this.state.width > 100 && this.state.height > 200 && (
                  <InputPointMap
                    key={mapComponent.mapLocationId + this.state.aspectRatioString + get(mapComponent, "initialBearing", 0) + get(mapComponent, "initialPitch", 0)}
                    company={this.props.selectedCompany}
                    location={location}
                    map={map}
                    mapMode={this.state.mapMode}
                    point={yourLocationFeature}
                    initialLocation={get(mapComponent, "initialLocation", null)}
                    onPointChanged={this.onYourLocationPointChanged}
                    onBoundsChanged={this.onInitialLocationBoundsChanged}
                    bearing={get(mapComponent, "initialBearing", 0)}
                    pitch={get(mapComponent, "initialPitch", 0)}
                  />
                )}
              </MapContainer>
            </SizeWrapper>
          </CenteredWrapper>
        </>
      )
    }

    return (
      <>
        <Hidden xs sm md>
          <div className={style.singleView}>
            <div className={style.row}>
              <div className={style.slimListContainer}>
                <div className={style.scroll}>
                  { this.mapConfigurationElement(mapComponent) }
                  <div style={{ paddingTop: "80px" }} />
                </div>
              </div>
              <div className={style.bigSideBar}>
                { rightSide }
              </div>
            </div>
          </div>
        </Hidden>

        <Visible xs sm md>
          <div className={style.singleView}>
            <div className={style.slimScroll}>
              { this.mapConfigurationElement() }
              <div style={{ paddingTop: "80px" }} />
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screenView: state.screenView,
    screenViewForm: state.screenView.form,
    isLoading: state.loading.screenView,
    isLoadingLocation: state.loading.location,
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    user: state.auth.user,
    locations: state.screenView.locations,
    maps: state.screenView.maps
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    updateScreenViewForm: screenViewActions.updateScreenViewForm,
    getScreenLocation: locationActions.getScreenLocation,
    getScreenFloorMap: locationActions.getScreenFloorMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenViewMap);

const MapContainerOptions = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  box-sizing: border-box;

  box-shadow: inset 0 -1px 0 0 #dbdbdb;
`;

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 56px); // Adjust for any offsets
  width: 100%;
  overflow: hidden;
  background-color: #555;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SizeWrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const InputTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  margin-right: 10px;
  margin-left: 10px;
`;