import { Visible, Hidden, setConfiguration } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import IntersectionObserverWrapper from "../../components/IntersectionObserverWrapper";
import bldngAiLogo from "../../bldng-logo.jpg";
import CompanySelector from "../CompanySelector";
import { signoutUser, getProfile, getVortoIds } from "../../actions";
import { getCompanyId, Role } from "../../helpers";
import * as authActions from "../../actions/auth";
import style from "./style.module.scss";

const cookies = new Cookies();

class Header extends Component {

  constructor(props) {
    // console.log("Header.constructor()");
    super(props);
    this.state = { 
      hidden: true,
      showAccountMenu: false
    };
    this.blackListPath = ["/report-view", "/status", "/dashboard", "/signin"];
    this.whiteListPath = ["/insights"];

    this.openPaths = ["/dashboard/", "/terms-of-service", "/privacy-policy"];
    
    
    // if (this.openPaths.find(path => this.props.location.pathname.includes(path))) {
    //   console.log("Skip");
    // }
    // else 

    // Ignore cookies and profile in dashboard view
    if (!this.props.location.pathname.includes("/dashboard/")) {
      if (!this.props.hasCheckedCookie) {
        this.props.checkCookie();
      }
      else if (this.props.authenticated) {
        this.props.getProfile();
        this.props.getVortoIds();
      }
    }

    this.logMeOut = this.logMeOut.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
    this.accountToggle = this.accountToggle.bind(this);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.getCompanyElement = this.getCompanyElement.bind(this);
    this.getLogoElement = this.getLogoElement.bind(this);

    // Set gutter width for mobile
    setConfiguration({ gutterWidth: 20 });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.hasCheckedCookie && this.props.hasCheckedCookie) {
      if (this.props.authenticated) {
        this.props.getProfile();
        this.props.getVortoIds();
      }
      else if (!this.openPaths.find(path => this.props.location.pathname.includes(path))) {
        this.props.history.push("/signin");
      }
    }
  }

  onLogoClick() {
    if (this.props.authenticated) {
      const companyId = getCompanyId();
      if (companyId) {
        this.props.history.push(`/companies/${companyId}/`);
      }
      else {
        // Get company id from Redux
        const selectedCompanyId = get(this.props.selectedCompany, "_id", false);
        if (selectedCompanyId) {
          this.props.history.push(`/companies/${selectedCompanyId}/`);
        }
      }
    }
  }

  getLogoElement() {
    return (
      <div
        tabIndex="0"
        role="button"
        className={style.brandImage}
        onClick={this.onLogoClick}
        onKeyDown={this.onLogoClick}
      >
        <div style={{ display: "flex", width: "60px", height: "60px", justifyContent: "center", padding: "8px", boxSizing: "border-box" }} >
          <img src={bldngAiLogo} alt="Logo" height="100%" />
        </div>
      </div>
    );
  }

  getCompanyElement() {
    if (this.props.authenticated) {
      if (isEmpty(this.props.user)) {
        return <div/>;
      }
      
      if (get(this.props.user, "companies", []).length > 0) {
        return (
          <div className={style.title}>
            <CompanySelector history={this.props.history} />
          </div>
        );
      }
    }

    return <div className={style.titleSignIn}>{this.props.title}</div>;
  }

  getNavigationBurger() {
    return (
      <div className={style.navigationBurger}>
        <ul>
          <li>
            <div
              tabIndex="0"
              role="button"
              className={style.navigation}
              onClick={this.menuToggle}
              onKeyDown={this.menuToggle}
            >
              &#9776;
            </div>
          </li>
        </ul>
      </div>
    );
  }

  getLoginButton() {
    const loginElement = (
      <li>
        <div
          tabIndex="0"
          role="button"
          className={style.logIn}
          onClick={() => this.props.history.push("/signin")}
          onKeyPress={() => this.props.history.push("/signin")}
        >
          Sign in
        </div>
      </li>
    );

    return (
      <div>
        <Hidden xs sm md>
          <div className={style.rightTab}>
            <ul>{loginElement}</ul>
          </div>
        </Hidden>
        <Visible xs sm md>
          {loginElement}
        </Visible>
      </div>
    );
  }

  getLogoutButton() {
    const logoutElement = (
      <li>
        <div
          tabIndex="0"
          role="button"
          className={style.logOut}
          onClick={this.logMeOut}
          onKeyDown={this.logMeOut}
        >
          Sign out
        </div>
      </li>
    );

    return (
      <div>
        <Hidden xs sm md>
          <div className={style.rightTab}>
            <ul>{logoutElement}</ul>
          </div>
        </Hidden>
        <Visible xs sm md>
          {logoutElement}
        </Visible>
      </div>
    );
  }

  getAccountButton() {
    return (
      <div
        tabIndex="0"
        role="button"
        className={style.accountIcon}
        onClick={this.accountToggle}
      >
        <FontAwesomeIcon icon={faUserCircle} color="#999" size="2x" />
      </div>
    );
  }

  getAccountMenu() {

    const selectedCompanyId = getCompanyId();
    const menu = [
      {
        name: "Account",
        link: "account",
        onClick: (e) => {
          const link = `/companies/${selectedCompanyId}/account`;
          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }

          this.setState({ showAccountMenu: false });
        }
      },
      {
        name: "New company",
        hiddenForAdmins: true,
        hide: this.props.role < Role.Support,
        onClick: (e) => {
          const link = `/companies/${selectedCompanyId}/new-company`;
          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
          
          this.setState({ showAccountMenu: false });
        }
      },
      {
        name: "Terms of service",
        link: "terms-of-service",
        onClick: (e) => {
          const link = `/companies/${selectedCompanyId}/terms-of-service`;
          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
          
          this.setState({ showAccountMenu: false });
        }
      },
      {
        name: "Privacy policy",
        link: "privacy-policy",
        onClick: (e) => {
          const link = `/companies/${selectedCompanyId}/privacy-policy`;
          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
          
          this.setState({ showAccountMenu: false });
        }
      },
      {
        name: "Sign out",
        onClick: (e) => {
          if (process.env.NODE_ENV === "development") {
            window.location.href = `http://localhost:8081/logout`;
          } else {
            window.location.href = `${window.location.origin}/logout`;
          }
        },
      }
    ];
    
    return (
      <>
        <div className={style.accountMenu}>
          {menu.map((item, index) => {

            if (item.hide) {
              return null;
            }
            
            return (
              <div className={style.accountMenuItem} key={index} onClick={item.onClick} style={{ color: item.hiddenForAdmins ? "#666" : undefined }}>
                { item.name }
              </div>
            );
          })}
        </div>
        <div className={style.exitBackground} onClick={this.accountToggle} />
      </>
    );
  }

  accountToggle() {
    this.setState(prevState => ({ showAccountMenu: !prevState.showAccountMenu }));
  }

  menuToggle() {
    this.setState(prevState => ({ hidden: !prevState.hidden }));
  }

  logMeOut() {
    if (process.env.NODE_ENV === "development") {
      window.location.href = `http://localhost:8081/logout`;
    } else {
      window.location.href = `${window.location.origin}/logout`;
    }
  }

  render() {
    // const cookies = new Cookies();

    // console.log("Header.render()", this.props);
    // console.log("Header.render() authenticated", this.props.authenticated);
    let menuElement;
    if (
      this.blackListPath.some(x => this.props.location.pathname.includes(x)) &&
      !this.whiteListPath.some(x => this.props.location.pathname.includes(x))
    ) {
      // console.log("Header.return");
      return <div />;
    }

    const links = [];
    if (this.props.authenticated) {
      const selectedCompanyId = getCompanyId();
      if (!selectedCompanyId || isEmpty(this.props.user)) {
        
        // Show logo on terms and privacy pages
        const isInTermsOrPrivacy = this.props.location.pathname.includes("/terms-of-service") || this.props.location.pathname.includes("/privacy-policy");

        return (
          <header className={style.header} role="banner">
            <div className={style.smallMargin}>
              <div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", maxWidth: "calc(100% - 85px)" }}>
                  <Hidden xs sm>
                    {this.getLogoElement()}
                  </Hidden>
                  { isInTermsOrPrivacy && <div className={style.titleSignIn}>{this.props.title}</div> }
                </div>
                { this.getLogoutButton() }
              </div>
            </div>
          </header>
        );
      } 
      else {

        if (this.props.auth.hasAdminRole) {
          links.push({ pathname: `/companies/${selectedCompanyId}/home`, label: `Home` });
          links.push({ pathname: `/companies/${selectedCompanyId}/locations`, label: `Building` });
          links.push({ pathname: `/companies/${selectedCompanyId}/org`, label: `Organization` });

          if (get(this.props.selectedCompany, "serviceFeatures.enableApp", false) || this.props.auth.hasSupportRole) {
            links.push({ pathname: `/companies/${selectedCompanyId}/app`, label: `App`, hiddenForAdmins: !get(this.props.selectedCompany, "serviceFeatures.enableApp", false) });
          }
        }
    
        if ((this.props.auth.hasAdminRole && get(this.props.selectedCompany, "serviceFeatures.enableScreen", false)) || this.props.auth.hasSupportRole) {
          links.push({ pathname: `/companies/${selectedCompanyId}/screens`, label: `Screens`, hiddenForAdmins: !get(this.props.selectedCompany, "serviceFeatures.enableScreen", false) });
        }
    
        if (get(this.props.selectedCompany, "serviceFeatures.enableWorkplaceAnalysis", false) || this.props.auth.hasSupportRole) {
          links.push({ pathname: `/companies/${selectedCompanyId}/reports`, label: `Reports`, hiddenForAdmins: !get(this.props.selectedCompany, "serviceFeatures.enableWorkplaceAnalysis", false) });
        }
    
        if (get(this.props.selectedCompany, "serviceFeatures.enableInsights", false) || this.props.auth.hasSupportRole) {
          links.push({ pathname: `/companies/${selectedCompanyId}/insights`, label: `Insights`, hiddenForAdmins: !get(this.props.selectedCompany, "serviceFeatures.enableInsights", false)  });
        }
    
        if (this.props.auth.hasInstallerRole) {
          links.push({ pathname: `/companies/${selectedCompanyId}/installation`, label: `Installation` });
        }

        if (this.props.auth.hasUserRole) {

          // Reload when switching company based on list of links
          const key = links.map(link => link.pathname).join("");
          // console.log("Header.render() selectedCompany", this.props.selectedCompany);
          // console.log("Header.render() key", key);

          if (!isEmpty(this.props.selectedCompany)) {
            menuElement = (
              <IntersectionObserverWrapper 
                key={key}
                links={links}
                history={this.props.history}
                topMenu
              />
            )
          }
        }
        else {
          menuElement = null;
        }
      }
    }

    // If only a user - block access
    if (this.props.role === Role.User) {
      return (
        <header className={style.header} role="banner">
          <div className={style.smallMargin}>
            <div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", maxWidth: "calc(100% - 85px)" }}>
                <Hidden xs sm>
                  {this.getLogoElement()}
                </Hidden>
                {this.getCompanyElement()}
              </div>
              { this.props.authenticated ? this.getLogoutButton() : this.getLoginButton() }
            </div>
          </div>
        </header>
      );
    }

    return (
      <header className={style.header} role="banner">
        <div className={style.smallMargin}>
          <div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", maxWidth: "calc(100% - 85px)" }}>
              <Hidden xs sm>
                {this.getLogoElement()}
              </Hidden>
              {this.getCompanyElement()}
              {menuElement}
            </div>
            <div style={{ display: "flex", position: "absolute", right: 0, zIndex: 1 }}>
              { this.getAccountButton() }
              { this.state.showAccountMenu && this.getAccountMenu() }
            </div>
          </div>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasCheckedCookie: state.auth.hasCheckedCookie,
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    role: state.auth.role,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { 
      signoutUser, 
      getProfile, 
      getVortoIds,
      checkCookie: authActions.checkCookie
     },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
