import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux"
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import Loader from "../../components/Loader";
import InputModal from "../../components/InputModal";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import ScreenGroupViewDetails from "./screenGroupViewDetails";
import * as screenViewActions from "../../actions/screenViews";
import style from "./style.module.scss";
import styled from "styled-components";

class ScreenGroupViews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      search: undefined,
      sortBy: "createdAt",
      sortOrder: "desc",
      showNewScreenViewModal: false,
      newScreenViewName: "",
      newScreenViewLayout: {},
      highlightedId: null
    }

    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onNewScreenViewClick = this.onNewScreenViewClick.bind(this);
    this.onDuplicateScreenViewClick = this.onDuplicateScreenViewClick.bind(this);
    this.onNewScreenViewNameChanged = this.onNewScreenViewNameChanged.bind(this);
    this.onNewScreenView = this.onNewScreenView.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.getViewTable = this.getViewTable.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.props.screen.views)) {
      this.props.getScreenViews(this.props.screenGroupId);
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log("onRowClick", e, rowInfo, column, instance);
        if (column.name !== 'isSelected' && rowInfo !== undefined) {

          const link = `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views/${rowInfo.original.id}/details`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.id === this.props.match.params.screenViewId ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onNewScreenViewClick() {
    this.setState({ showNewScreenViewModal: true, newScreenViewName: "", newScreenViewLayout: {} });
  }

  onDuplicateScreenViewClick(originalView) {
    console.log("onDuplicateScreenViewClick", originalView)
    this.setState({ showNewScreenViewModal: true, newScreenViewName: `${originalView.name} (copy)`, newScreenViewLayout: JSON.parse(JSON.stringify(originalView.layout)) });
  }

  onNewScreenViewNameChanged(event) {
    this.setState({ newScreenViewName: event.target.value });
  }

  onNewScreenView() {

    const name = this.state.newScreenViewName;
    const layout = this.state.newScreenViewLayout;

    this.props.createScreenView(this.props.screenGroupId, { name, layout }, this.props.history.push);
  }

  onSearchClick() {
    this.setState({ search: this.state.searchText });
  }

  onSearchChange(value) {
    this.setState({ searchText: value === '' ? undefined : value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  getViewTable() {
    const views = this.props.screen.views;
    
    // Filter views with search
    let filteredViews = views;
    if (this.state.search) {
      filteredViews = views.filter(v => v.name.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    // Sort views
    let sortedViews = filteredViews.sort((a, b) => {
      if (this.state.sortBy === "createdAt") {
        if (this.state.sortOrder === "desc") {
          return moment(b.createdAt).diff(a.createdAt);
        }
        else {
          return moment(a.createdAt).diff(b.createdAt);
        }
      }
      else if (this.state.sortBy === "name") {
        if (this.state.sortOrder === "desc") {
          return b.name.localeCompare(a.name);
        }
        else {
          return a.name.localeCompare(b.name);
        }
      }
      else {
        return 0;
      }
    });

    // Fix reload
    sortedViews = sortedViews.map(s => s);

    return (
      <Table
        data={sortedViews}
        noDataText={"No views"}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={this.onSortedChange}
        columns={[
          {
            header: "Created",
            accessorKey: "createdAt",
            defaultSortDesc: true,
            width: 140,
            cell: ({ row }) => 
            (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
              {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
            </span>)
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "id",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable -highlight"
      />
    );
  }

  render() {
    // console.log("ScreenGroupViews.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const description = `The content displayed on a device when a screen is loaded is determined by the default view assigned to that device. You have the flexibility to create multiple views tailored to different devices, ensuring an optimal experience across platforms. Additionally, you can enable users to switch between views on a single device, such as when navigating between different floors on a map.`;

    const options = (
      <TopRowOptions
        description={description}
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <AdjustedButtonText key="a1"><SmallButton key="b1" text="New view" onClick={this.onNewScreenViewClick} singleLine noMargin /></AdjustedButtonText>
        ]}
      />
    );

    return (
      <>
        <Hidden xs sm md lg>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  { options }
                  { this.getViewTable() }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <ScreenGroupViewDetails {...this.props} onDuplicateScreenViewClick={this.onDuplicateScreenViewClick} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={style.mainContainerHidden}>
            <div className={style.scroll}>
              { options }
              { this.getViewTable() }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.slimScroll}>
              { options }
              { this.getViewTable() }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>

        <InputModal
          show={this.state.showNewScreenViewModal}
          onHide={() => this.setState({ showNewScreenViewModal: false })}
          title="New view"
          inputLabel="Name"
          inputValid={this.state.newScreenViewName}
          inputValue={this.state.newScreenViewName}
          onInputChange={this.onNewScreenViewNameChanged}
          primaryBtn={{
            text: "Create",
            onClick: this.onNewScreenView
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showNewScreenViewModal: false })
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    screenView: state.screenView,
    form: state.screen.form,
    isLoading: state.loading.screen,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createScreenView: screenViewActions.createScreenView,
    getScreenViews: screenViewActions.getScreenViews,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupViews);

const AdjustedButtonText = styled.div`
  span {
    position: relative;
    top: -1px;
  }
`;