import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import TextBox from "../../components/TextBox";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import Loader from "../../components/Loader";
import { NewControlledDropdownSelection } from "../../components/DropdownSelection";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";

class ScreenGroupLinkDetails extends Component {

  constructor(props) {
    super(props);
    this.onUpdateLink = this.onUpdateLink.bind(this);
    this.onDeleteLink = this.onDeleteLink.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.linkId) {
      if (this.props.link.id !== this.props.match.params.linkId) {
        this.props.getScreenLink(this.props.match.params.screenGroupId, this.props.match.params.linkId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.linkId) {
      if (this.props.link.id !== this.props.match.params.linkId) {
        this.props.getScreenLink(this.props.match.params.screenGroupId, this.props.match.params.linkId);
      }
    }
  }

  onUpdateLink(name) {
    this.props.updateScreenLink(this.props.screenGroupId, this.props.link.id, { name });
  }

  onDeleteLink() {
    this.props.deleteScreenLink(this.props.screenGroupId, this.props.link.id);
  }

  render() {
    // console.log("ScreenConfigDevices.render", this.props);

    if (isEmpty(this.props.match.params.linkId) || isEmpty(this.props.link.createdAt)) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader />;
    }

    if (isEmpty(this.props.link.id)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/links/${this.props.link.id}/details`, label: `Details` });
    
    // Build the drop down options (view ids with view names)
    const viewOptions = this.props.views.map(view => {
      return { id: view.id, name: view.name };
    });

    // Add "None" option
    viewOptions.unshift({ id: "", name: "None" });

    return (
      <div>
        <DetailsHeader
          title={this.props.link.name}
          isLoading={this.props.isLoading}
          canUpdateName
          onNameSaveClicked={this.onUpdateLink}
          canDelete
          onDeleteClicked={this.onDeleteLink}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <NewControlledDropdownSelection
          label="Default view"
          value={this.props.link.defaultViewId}
          options={viewOptions}
          onChange={(event) => this.props.updateScreenLink(this.props.screenGroupId, this.props.link.id, { defaultViewId: event.target.value })}
          style={{ marginTop: "20px" }}
        />
        <TextBox label="Link" value={this.props.link.link ?? ""} showCopy />
        {/* <TextBox label="Description" value={this.props.link.description ?? ""} size="medium" /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    link: state.screen.link,
    views: state.screen.views,
    isLoading: state.loading.screen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenLink: screenActions.getScreenLink,
    updateScreenLink: screenActions.updateScreenLink,
    deleteScreenLink: screenActions.deleteScreenLink
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupLinkDetails);