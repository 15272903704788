import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import TextBox from "../../components/TextBox";
import * as screenActions from "../../actions/screens";

class ScreenDetailsInfo extends Component {

  constructor(props) {
    super(props);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }

  onDescriptionChange(event) {
    this.props.updateScreenGroupForm({ description: event.target.value });
  }

  render() {
    console.log("ScreenDetails.render", this.props);

    if (isEmpty(this.props.screenGroupId) || isEmpty(this.props.screen.name)) {
      return null;
    }

    return (
      <>
        <TextBox label="Description" value={this.props.form.description ?? ""} size="medium" onChange={this.onDescriptionChange} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screens,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateScreenGroupForm: screenActions.updateScreenGroupForm
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenDetailsInfo);