import React, { Component } from "react";
import { Container, Col, Row, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import InputModal from "../../components/InputModal";
import ScreenGroupLinkDetails from "./screenGroupLinkDetails";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";
import styled from "styled-components";

class ScreenGroupLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      search: undefined,
      sortBy: "createdAt",
      sortOrder: "desc",
      highlightedId: null,
      newLinkName: "",
      showNewLinkModal: false,
    }

    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onNewLinkClick = this.onNewLinkClick.bind(this);
    this.onNewLinkChanged = this.onNewLinkChanged.bind(this);
    this.onNewLink = this.onNewLink.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getLinkTable = this.getLinkTable.bind(this);
  }

  componentDidMount() {
    console.log("ScreenGroupLinks.componentDidMount", this.props);
    this.props.getScreenLinks(this.props.screenGroupId);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo) {
          let link = `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/links/${rowInfo.original.id}/details`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.id === this.props.match.params.linkId ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onNewLinkClick() {
    this.setState({ showNewLinkModal: true });
  }

  onNewLinkChanged(event) {
    this.setState({ newLinkName: event.target.value });
  }

  onNewLink() {
    this.props.createScreenLink(this.props.screenGroupId, { name: this.state.newLinkName }, (id) => { 
      this.setState({ showNewLinkModal: false });
      this.props.getScreenLinks(this.props.screenGroupId);
      this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/links/${id}/details`);
    });
  }

  onSearchClick() {
    this.setState({ search: this.state.searchText });
  }

  onSearchChange(value) {
    this.setState({ searchText: value === '' ? undefined : value });
  }

  getLinkTable() {
    const links = this.props.screen.links;

    // Filter links with search
    let filteredLinks = links;
    if (this.state.search) {
      filteredLinks = links.filter(v => v.authCode.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    // Sort links
    let sortedLinks = filteredLinks.sort((a, b) => {
      if (this.state.sortBy === "createdAt") {
        if (this.state.sortOrder === "desc") {
          return moment(b.createdAt).diff(a.createdAt);
        }
        else {
          return moment(a.createdAt).diff(b.createdAt);
        }
      }
      else if (this.state.sortBy === "name") {
        if (this.state.sortOrder === "desc") {
          return b.name.localeCompare(a.name);
        }
        else {
          return a.name.localeCompare(b.name);
        }
      }
      else {
        return 0;
      }
    });

    // Fix reload
    sortedLinks = sortedLinks.map(s => s);

    return (
      <Table
        data={sortedLinks}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc" });
        }}
        noDataText={"No links"}
        columns={[
          {
            header: "Created",
            accessorKey: "createdAt",
            defaultSortDesc: true,
            width: 140,
            cell: ({ row }) => 
            (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
              {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
            </span>)
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "id",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        // hideHeaders
        className="-row-clickable -highlight"
      />
    );
  }

  render() {
    // console.log("ScreenConfigDevices.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (this.props.screenGroupId !== "new" && isEmpty(this.props.screen)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    // Create table for links  
    let tableElement = this.getLinkTable();
    let description = `A link includes the authentication token within the URL, allowing it to work on any device. However, due to this simplified security setup, anyone with the link can access it. If you want to maintain control over access, ensure the link is not shared unnecessarily. To revoke access, simply delete the link.`;

    const options = (
      <TopRowOptions
        description={description}
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <AdjustedButtonText key="ab1"><SmallButton key="b1" text="New link" onClick={this.onNewLinkClick} singleLine noMargin /></AdjustedButtonText>
        ]}
      />
    );

    return (
      <>
        <Hidden xs sm md lg>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  { options }
                  { tableElement }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <ScreenGroupLinkDetails {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={style.mainContainerHidden}>
            <div className={style.scroll}>
              { options }
              { tableElement }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.slimScroll}>
              { options }
              { tableElement }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>

        <InputModal
          show={this.state.showNewLinkModal}
          onHide={() => this.setState({ showNewLinkModal: false })}
          title="New link"
          // text=""
          inputLabel="Name"
          inputValid={this.state.newLinkName}
          inputValue={this.state.newLinkName}
          onInputChange={this.onNewLinkChanged}
          primaryBtn={{
            text: "Create",
            onClick: this.onNewLink
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showNewLinkModal: false })
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenDevices: screenActions.getScreenDevices,
    updateScreenDevice: screenActions.updateScreenDevice,
    deleteScreenDevice: screenActions.deleteScreenDevice,
    getScreenLinks: screenActions.getScreenLinks,
    createScreenLink: screenActions.createScreenLink,
    updateScreenLink: screenActions.updateScreenLink,
    deleteScreenLink: screenActions.deleteScreenLink,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupLinks);

const AdjustedButtonText = styled.div`
  span {
    position: relative;
    top: -1px;
  }
`;