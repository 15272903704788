import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import TextBox from "../../components/TextBox";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import Loader from "../../components/Loader";
import { NewControlledDropdownSelection } from "../../components/DropdownSelection";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";

class ScreenGroupDeviceDetails extends Component {

  constructor(props) {
    super(props);
    this.onEnableDevice = this.onEnableDevice.bind(this);
    this.onDisableDevice = this.onDisableDevice.bind(this);
    this.onDeleteDevice = this.onDeleteDevice.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.deviceId) {
      if (this.props.device.id !== this.props.match.params.deviceId) {
        this.props.getScreenDevice(this.props.match.params.screenGroupId, this.props.match.params.deviceId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.deviceId) {
      if (this.props.device.id !== this.props.match.params.deviceId) {
        this.props.getScreenDevice(this.props.match.params.screenGroupId, this.props.match.params.deviceId);
      }
    }
  }

  onEnableDevice() {
    this.props.updateScreenDevice(this.props.screenGroupId, this.props.device.id, { approvalState: "approved" });
  }

  onDisableDevice() {
    this.props.updateScreenDevice(this.props.screenGroupId, this.props.device.id, { approvalState: "disabled" });
  }

  onDeleteDevice() {
    this.props.deleteScreenDevice(this.props.screenGroupId, this.props.device.id);
  }

  render() {
    // console.log("ScreenConfigDevices.render", this.props);

    if (isEmpty(this.props.match.params.deviceId) || isEmpty(this.props.device.connectedAt)) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader />;
    }

    if (isEmpty(this.props.device.id)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/devices/${this.props.device.id}/details`, label: `Details` });

    const title = this.props.device.name ?? `Device ${this.props.device.authCode}`;

    let customButtonText = "";
    let customButtonColor = "";
    let onCustomButtonClicked = null;
    if (this.props.device.approvalState === "approved") {
      customButtonText = "Disable";
      customButtonColor = "white-red";
      onCustomButtonClicked = this.onDisableDevice;
    }
    else if (this.props.device.approvalState === "disabled") {
      customButtonText = "Enable";
      customButtonColor = "white";
      onCustomButtonClicked = this.onEnableDevice;
    }
    else {
      customButtonText = "Approve";
      customButtonColor = "blue"
      onCustomButtonClicked = this.onEnableDevice;
    }
    
    // Build the drop down options (view ids with view names)
    const viewOptions = this.props.views.map(view => {
      return { id: view.id, name: view.name };
    });

    // Add "None" option
    viewOptions.unshift({ id: "", name: "None" });

    return (
      <div>
        <DetailsHeader
          title={title}
          customButtonText={customButtonText}
          customButtonColor={customButtonColor}
          canUseCustomButton
          canDelete={true}
          isLoading={this.props.isLoading}
          onCustomButtonClicked={onCustomButtonClicked}
          onDeleteClicked={this.onDeleteDevice}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <NewControlledDropdownSelection
          label="Start view"
          value={this.props.device.defaultViewId}
          options={viewOptions}
          onChange={(event) => this.props.updateScreenDevice(this.props.screenGroupId, this.props.device.id, { defaultViewId: event.target.value })}
          style={{ marginTop: "20px" }}
        />
        {/* <TextBox label="Description" value={this.props.device.description ?? ""} size="medium" /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    device: state.screen.device,
    views: state.screen.views,
    isLoading: state.loading.screen
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenDevice: screenActions.getScreenDevice,
    updateScreenDevice: screenActions.updateScreenDevice,
    deleteScreenDevice: screenActions.deleteScreenDevice
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupDeviceDetails);