import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import TextBox from "../../components/TextBox";
import * as screenViewActions from "../../actions/screenViews";
import styled from "styled-components";

class ScreenGroupViewDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.iframeContainerRef = React.createRef();
    this.iframeRef = React.createRef();

    this.scaleIframe = this.scaleIframe.bind(this);
    this.onEditViewClicked = this.onEditViewClicked.bind(this);
    this.onEditViewNameSave = this.onEditViewNameSave.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onDeleteViewClicked = this.onDeleteViewClicked.bind(this);
  }

  componentDidMount() {
    // console.log("ScreenGroupViewDetails.componentDidMount");
    if (isEmpty(this.props.match.params.screenViewId)) {
      this.props.clearScreenViewData();
    }
    else if (this.props.screenView.id !== this.props.match.params.screenViewId) {
      this.props.getScreenView(this.props.screenGroupId, this.props.match.params.screenViewId);
    }

    this.scaleIframe();
    
    window.addEventListener('resize', this.scaleIframe);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.scaleIframe);
  }

  componentDidUpdate(prevProps) {
    // console.log("ScreenGroupViewDetails.componentDidUpdate");
    // Only load data if id change
    if (isEmpty(this.props.match.params.screenViewId)) {
      this.props.clearScreenViewData();
    }
    else if (this.props.screenView.id !== this.props.match.params.screenViewId) {
      this.props.getScreenView(this.props.screenGroupId,this.props.match.params.screenViewId);
    }

    this.scaleIframe();
  }

  scaleIframe() {
    const iframeContainer = this.iframeContainerRef.current;
    const iframe = this.iframeRef.current;

    if (iframeContainer && iframe) {
      // console.log("ScreenGroupViewDetails.scaleIframe - perform scaling");

      // Get the dimensions of the iframe container
      const containerWidth = iframeContainer.clientWidth;
      const containerHeight = iframeContainer.clientHeight;

      // Calculate the scaling factor based on the width
      // For example, change scale between 0.25 and 0.5 depending on the container width
      let scale;
      if (containerWidth < 400) {
        scale = 0.25; // Small screen, small scale
      } 
      else if (containerWidth < 600) {
        scale = 0.35; // Medium screen, medium scale
      } 
      else {
        scale = 0.5; // Large screen, larger scale
      }

      // Set the width and height of the iframe to match the scaling factor
      iframe.style.width = `${containerWidth / scale}px`;
      iframe.style.height = `${containerHeight / scale}px`;

      // Apply the scale transformation
      iframe.style.transform = `scale(${scale})`;
      iframe.style.transformOrigin = '0 0';
    }
  }

  onEditViewClicked(event) {
    const link = `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/views/${this.props.screenView.id}/layout`;

    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  onEditViewNameSave(name) {
    const view = { name };
    this.props.updateScreenView(this.props.screenGroupId, this.props.match.params.screenViewId, view);
  }

  onDescriptionChange(event) {
    this.props.updateScreenViewForm({ description: event.target.value });
  }

  onDeleteViewClicked() {
    this.props.deleteScreenView(this.props.screenGroupId, this.props.screenView.id, this.props.history.push);
  }

  render() {
    // console.log("ScreenGroupViewDetails.render", this.props);

    if (isEmpty(this.props.screenView.name)) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/views/${this.props.screenView.id}/details`, label: `Details` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/views/${this.props.screenView.id}/preview`, label: `Preview` });

    const title = `${this.props.screenView.name}`;

    let customButtonText = "Duplicate";
    let customButtonColor = "blue";

    let content = null;
    if (this.props.location.pathname.includes("preview")) {
      
      let url = "";
      if (this.props.screen.inDev) {
        url = `https://screen.dev.bldng.ai/${this.props.match.params.companyId}/${this.props.screenGroupId}?devPreview=true&defaultViewId=${this.props.screenView.id}`;
      }
      else {
        url = `https://screen.bldng.ai/${this.props.match.params.companyId}/${this.props.screenGroupId}?preview=true&defaultViewId=${this.props.screenView.id}`;
      }
      
      content = (
        <>
          <div style={{ marginTop: "25px" }} />
          <p>NB! The elements in the preview might scale differently on big screens.</p>
          <div ref={this.iframeContainerRef} style={{ width: "100%", aspectRatio:(16/9), marginTop: "0px", overflow: "hidden", borderWidth: "1px", borderColor: "#DBDBDB", borderStyle: "solid" }}>
            <StyledIframe ref={this.iframeRef} src={url} />
          </div>
        </>
      );
    }
    else {
      content = (
        <TextBox label="Description" value={this.props.form.description ?? ""} size="medium" onChange={this.onDescriptionChange} />
      );
    }

    return (
      <div>
        <DetailsHeader
          title={title}
          customButtonText={customButtonText}
          customButtonColor={customButtonColor}
          canUseCustomButton={true}
          canUpdate={true}
          canUpdateName={true}
          canDelete={true}
          isLoading={this.props.isLoading}
          onCustomButtonClicked={() => this.props.onDuplicateScreenViewClick(this.props.screenView)}
          onNameSaveClicked={this.onEditViewNameSave}
          onEditClicked={this.onEditViewClicked}
          onDeleteClicked={this.onDeleteViewClicked}
        />
        <SubNavigation links={subNavigationLinks} slim />
        { content }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    screenView: state.screenView,
    form: state.screenView.form,
    isLoading: state.loading.screen
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenView: screenViewActions.getScreenView,
    updateScreenView: screenViewActions.updateScreenView,
    deleteScreenView: screenViewActions.deleteScreenView,
    updateScreenViewForm: screenViewActions.updateScreenViewForm,
    clearScreenViewData: screenViewActions.clearScreenViewData,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupViewDetails);

const StyledIframe = styled.iframe`
  position: relative;
  width: 1000%;
  height: 1000%;
  transform: scale(0.1);
  transform-origin: 0 0;
  border: 0;
`;