import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  name: "",
  description: undefined,
  isPrivate: true,
  allowInteractions: false,
  resetTimer: undefined,
  language: undefined,
  configOverrides: undefined,
  inDev: false,
  form: {},

  views: [],

  devices: [],
  device: {},
  links: [],
  link: {}
};

export default function screenReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SCREEN_GROUP) {
        return { ...initialState, id: action.metadata.id };
      }

      if (action.fetchType === types.GET_SCREEN_VIEWS) {
        return { ...state, views: [] };
      }

      if (action.fetchType === types.GET_SCREEN_DEVICE) {
        return { ...state, device: { id: action.metadata.id } };
      }

      if (action.fetchType === types.GET_SCREEN_LINK) {
        return { ...state, link: { id: action.metadata.id } };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SCREEN_GROUP) {

        let newState = {
          ...state,
          id: action.payload.id,
          name: get(action.payload, "name", ""),
          description: get(action.payload, "description", undefined),
          isPrivate: get(action.payload, "isPrivate", true),
          allowInteractions: get(action.payload, "allowInteractions", false),
          hideViewIdentifier: get(action.payload, "hideViewIdentifier", false),
          useMeetingRoomCalendars: get(action.payload, "useMeetingRoomCalendars", false),
          resetTimer: get(action.payload, "resetTimer", undefined),
          language: get(action.payload, "language", undefined),
          configOverrides: get(action.payload, "configOverrides", undefined),
          inDev: get(action.metadata, "inDev", false)
        };

        // Keep form object if it is the same screen
        if (action.payload.id === state.form.id) {
          return newState;
        }

        newState.form = {
          id: get(action.payload, "id", ""),
          name: get(action.payload, "name", ""),
          description: get(action.payload, "description", undefined),
          allowInteractions: get(action.payload, "allowInteractions", false),
          hideViewIdentifier: get(action.payload, "hideViewIdentifier", false),
          useMeetingRoomCalendars: get(action.payload, "useMeetingRoomCalendars", false),
          resetTimer: get(action.payload, "resetTimer", undefined),
          language: get(action.payload, "language", undefined),
          configOverrides: get(action.payload, "configOverrides", undefined)
        };
          
        return newState;
      }

      if (action.fetchType === types.GET_SCREEN_VIEWS) {
        return { ...state, views: action.payload.results };
      }

      if (action.fetchType === types.GET_SCREEN_VIEW) {
        // Find and update view
        let newViews = JSON.parse(JSON.stringify(state.views));
        const index = newViews.findIndex((view) => view.id === action.payload.id);

        if (index === -1) {
          return state;
        }

        // Update view
        newViews[index] = { ...newViews[index], ...action.payload };

        return {...state, views: newViews };
      }

      if (action.fetchType === types.CREATE_SCREEN_GROUP) {
        toast.success("Screen created");
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_GROUP_FORM) {
        return {...state, form: action.payload };
      }

      if (action.fetchType === types.UPDATE_SCREEN_GROUP) {
        toast.success("Screen updated");
        return state;
      }

      if (action.fetchType === types.GET_SCREEN_DEVICES) {
        return {
          ...state,
          devices: action.payload.results
        };
      }

      if (action.fetchType === types.GET_SCREEN_DEVICE) {
        return {
          ...state,
          device: action.payload
        };
      }

      if (action.fetchType === types.UPDATE_SCREEN_DEVICE) {
        toast.success("Device updated");
        let newDevices = JSON.parse(JSON.stringify(state.devices));
        const index = newDevices.findIndex((device) => device.id === action.metadata.id);
        
        if (index === -1) {
          return state;
        }
        
        // Update device
        newDevices[index] = { ...newDevices[index], ...action.metadata };
        
        return {...state, devices: newDevices, device: { ...state.device, ...action.metadata } };
      }

      if (action.fetchType === types.DELETE_SCREEN_DEVICE) {
        toast.success("Device deleted");
        let newDevices = JSON.parse(JSON.stringify(state.devices));
        const index = newDevices.findIndex((device) => device.id === action.metadata.id);
        
        if (index === -1) {
          return state;
        }

        // Remove device from screen
        newDevices.splice(index, 1);
        
        return {...state, devices: newDevices };
      }

      if (action.fetchType === types.GET_SCREEN_LINKS) {
        return {
          ...state,
          links: action.payload.results
        };
      }

      if (action.fetchType === types.GET_SCREEN_LINK) {
        let newLinks = JSON.parse(JSON.stringify(state.links));
        const index = newLinks.findIndex((link) => link.id === action.payload.id);

        if (index === -1) {
          return {
            ...state,
            link: action.payload
          };
        }
        
        // Update link
        newLinks[index] = { ...newLinks[index], ...action.payload };

        return {
          ...state,
          link: action.payload,
          links: newLinks
        };
      }

      if (action.fetchType === types.UPDATE_SCREEN_LINK) {
        toast.success("Link updated");
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_LINK) {
        toast.success("Link deleted");
        let newLinks = JSON.parse(JSON.stringify(state.links));
        const index = newLinks.findIndex((link) => link.id === action.metadata.id);
        
        if (index === -1) {
          return state;
        }

        // Remove link from screen
        newLinks.splice(index, 1);
        
        return {...state, links: newLinks, link: {} };
      }

      if (action.fetchType === types.DELETE_SCREEN_GROUP) {
        toast.success("Screen deleted");
        return initialState;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SCREEN_GROUP) {
        toast.error(`${statusCode}: Could not get screen group`);
        return initialState;
      }

      if (action.fetchType === types.GET_SCREEN_VIEWS) {
        toast.error(`${statusCode}: Could not get screen views`);
        return state;
      }

      if (action.fetchType === types.CREATE_SCREEN_GROUP) {
        toast.error(`${statusCode}: Could not create screen group`);
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_GROUP) {
        toast.error(`${statusCode}: Could not update screen group`);
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_GROUP) {
        toast.error(`${statusCode}: Could not delete screen group`);
        return state;
      }

      if (action.fetchType === types.GET_SCREEN_DEVICES) {
        toast.error(`${statusCode}: Could not get devices`);
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_DEVICE) {
        toast.error(`${statusCode}: Could not update device`);
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_DEVICE) {
        toast.error(`${statusCode}: Could not delete device`);
        return state;
      }

      if (action.fetchType === types.GET_SCREEN_LINKS) {
        toast.error(`${statusCode}: Could not get links`);
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_LINK) {
        toast.error(`${statusCode}: Could not update link`);
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_LINK) {
        toast.error(`${statusCode}: Could not delete link`);
        return state;
      }

      return state;
    }

    case types.RESET_SCREEN_GROUP_FORM: {
      // Create form object
      const form = {
        id: state.id,
        name: state.name,
        description: state.description,
        mazeMap: state.mazeMap,
        allowInteractions: state.allowInteractions,
        resetTimer: state.resetTimer,
        language:  state.language,
        theme: state.theme
      };

      return {...state, form };
    }

    case types.CLEAR_DATA:
    case types.CLEAR_SCREEN_GROUP_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
